import React from "react";

import {
  CbBlockQuote,
  CbCopy,
  CbHeading,
  CbImage,
  CbImageGrid,
  CbLargeCopy,
  CbLeadText,
  CbLink,
  CbList,
  CbProjects,
  CbResources,
  CbSubheading,
  CbVideo,
  CbButton,
} from "@molecules";

import { EmbedCode } from "@atoms";

const ContentBuilder = ({ blocks, inverse }) => {
  let listCount = 0;
  return (
    <div className="content-builder">
      {blocks &&
        blocks.map(block => {
          if (block) {
            switch (block.type) {
              case "blockQuote":
                return <CbBlockQuote {...block} />;
              case "copy":
                return <CbCopy inverse={inverse} {...block} />;
              case "button":
                return <CbButton {...block} />;
              case "heading":
                return <CbHeading inverse={inverse} {...block} />;
              case "image":
                return <CbImage {...block} />;
              case "imageGrid":
                return <CbImageGrid {...block} />;
              case "largeCopy":
                return <CbLargeCopy {...block} />;
              case "leadText":
                return <CbLeadText {...block} />;
              case "link":
                return <CbLink {...block} />;
              case "list":
                if (block.restartCount) {
                  listCount = 0;
                }
                listCount += 1;
                return <CbList {...block} count={listCount} />;
              case "projects":
                return <CbProjects {...block} />;
              case "resources":
                return <CbResources {...block} />;
              case "subheading":
                return <CbSubheading inverse={inverse} {...block} />;
              case "video":
                return <CbVideo {...block} />;
              case "embed":
                return <EmbedCode {...block} />;
              default:
                return null;
            }
          }
          return null;
        })}
    </div>
  );
};

ContentBuilder.defaultProps = {};

export default ContentBuilder;
